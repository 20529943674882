* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;

  font-family: "Avenir", "League Spartan", "Mundial", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-weight: 400; /* or another weight you've defined */

  padding: 1.5rem 2rem 10rem 2rem;
  display: flex;
  justify-content: center;
}

@media (min-width: 551px) {
  body {
    padding: 2rem 5rem 10rem 5rem;
  }
}

@media (min-width: 801px) {
  body {
    padding: 2rem 5rem 0 5rem;
  }
}

@media (min-width: 1351px) {
  body {
    padding: 2rem 10rem 0 10rem;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
